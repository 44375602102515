import React from 'react';
import CustomSlider from '../components/CustomSlider';
import { Link, useNavigate } from 'react-router-dom';
import './Home.css';
import {
    LooksOne as LooksOneIcon,
    LooksTwo as LooksTwoIcon,
    Looks3 as Looks3Icon,
    Looks4 as Looks4Icon,
    Looks5 as Looks5Icon
} from '@mui/icons-material';

const Home = () => {
    const navigate = useNavigate();

    const cardsData = [
        {
            titulo: 'Plan Base', descripcion: 'Plan nutricional y entrenamiento mensual personalizados, con soporte online y revisiones regulares con tu entrenador y nutricionista.', precio: <p>Precio: <b>69 €/mes</b></p>
        },
        {
            titulo: 'Plan Entrenamiento', descripcion: 'Tabla de entrenamiento personalizada con ajustes iniciales, contacto online y revisión mensual para tu progreso.', precio: <p>Precio:  <b>30 €/mes</b></p>
        },
        {
            titulo: 'Plan Premiun', descripcion: 'Cuestionario inicial, dos planes nutricionales quincenales, lista de compras, contacto online y revisiones mensuales con tu nutricionista y entrenador.', precio: <p>Precio: <b>99 €/mes</b></p>
        },
        {
            titulo: 'Plan Nutrición', descripcion: 'Cuestionario inicial, plan nutricional mensual, 4 revisiones semanales y contacto inicial con tu nutricionista.', precio: <p>Precio: <b>55 €/mes</b></p>
        }
    ];
    return (
        <div>
            <CustomSlider />
            <div className="planes-header">
                <h4>NUESTROS PLANES DE ENTRENAMIENTO Y NUTRICIÓN ONLINE</h4>
                <p>Descubre los planes de ShapeU! que mejor se adapten a tus necesidades y objetivos. Ofrecemos una variedad de opciones para ayudarte a alcanzar tus metas de manera efectiva.</p>
                <p>Consulta el plan que más se ajuste a ti.</p>
            </div>
            <div className="home-planes">
                {cardsData.map((card, index) => (
                    <div key={index} className="card-home">
                        <h3>{card.titulo}</h3>
                        <p className='descripcion-card'>{card.descripcion}</p>
                        <p className='precio-card precio-card-home'>{card.precio}</p>
                        <button onClick={() => navigate(`/planes?card=${index}`)}>
                            Ir a Plan
                        </button>
                    </div>
                ))}
            </div>
            <div className='all-planes'><Link to='/planes'><button to className='plan-button'>Ver todos los planes</button></Link></div>
            <div className="home-container">
                <h1 className="home-title">¿Cómo empiezo con ShapeU!?</h1>
                <div className="content-container">
                    <div className="content-item">
                        <h2><LooksOneIcon />Primer paso</h2>
                        <p>Una vez estés en nuestra web y hayas visto el plan ShapeU! que más se ajuste a ti, pulsa en él y nos pondremos en contacto contigo para explicarte la forma de pago y poder comenzar. Una vez estemos en contacto y se realice el pago del plan elegido, te enviaremos un cuestionario para que lo cumplimentes y poder preparar tu plan totalmente personalizado y enfocado a tus objetivos.</p>
                    </div>
                    <div className="content-item">
                        <h2><LooksTwoIcon />Plan entregado</h2>
                        <p>Una vez entregado tu plan por correo, nos pondremos en contacto contigo, según vía whatsapp/correo para asegurarnos de que entiendes todo lo que nuestro plan te ofrece. En el caso de que tengamos que modificar cualquier variable se modificaría sin problema para que así tu plan se ajuste totalmente a ti y des comienzo a tu cambio. En este paso, también cuadramos todas las citas y revisiones que entren con el plan seleccionado.</p>
                    </div>
                    <div className="content-item">
                        <h2><Looks3Icon />Comienza el cambio</h2>
                        <p>Cuando comiences con el proceso recuerda que según tu plan tendrás diferentes accesos, tanto con tu entrenador como con tu nutricionista. Siempre que tengas una duda o necesites algo, ahí tienes nuestro contacto. Estaremos presentes en tu vida para que tu camino sea lo más fácil posible.</p>
                    </div>
                    <div className="content-item">
                        <h2><Looks4Icon />Ajustes y mejoras</h2>
                        <p>Se te hará entrega de unos documentos para rellenar de forma semanal los diferentes datos que necesitamos para poder ver tu proceso desde la distancia. Esto nos ayudará a poder hacerte un seguimiento continuo y que progreses en todos los aspectos del entrenamiento y la nutrición para que tu cambio sea lo más preciso posible.</p>
                    </div>
                    <div className="content-item-ultimo">
                        <h2><Looks5Icon />Revisiones</h2>
                        <p>Tendrás acceso a revisiones semanales y mensuales, ya sea por videollamada o por whatsapp según tu plan seleccionado, donde analizaremos mediante medidas objetivas tu evolución a lo largo del tiempo. Estos análisis nos ayudarán a ver tu progreso desde otro punto de vista y motivarte a seguir con tu cambio de vida.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
