import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { auth, googleProvider } from '../firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, updateProfile } from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from '@mui/icons-material/Google';
import './ModalLogin.css';

const ModalLogin = ({ type, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (type === 'login') {
        await signInWithEmailAndPassword(auth, email, password);
      } else if (type === 'register') {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
      }
      onClose();
    } catch (error) {
      setError("No se ha podido iniciar sesión. verifica tus credenciales.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      onClose(); // Cierra el modal si la autenticación fue exitosa
    } catch (error) {
      setError("No se ha podido iniciar sesión. El usuario no existe.");
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Por favor, introduce tu email.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setError('Se ha enviado un correo para restablecer tu contraseña.');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box className="modal-box">
        <Button onClick={onClose} className="modal-close-button">
          <CloseIcon />
        </Button>
        <Typography variant="h6" className="modal-title">
          {type === 'login' ? 'Iniciar Sesión' : 'Registrarse'}
        </Typography>
        <form onSubmit={handleSubmit} className="modal-form">
          {type === 'register' && (
            <>
              <TextField
                label="Nombre"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Apellido"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </>
          )}
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={!email || !password}>
            {type === 'login' ? 'Iniciar Sesión' : 'Registrarse'}
          </Button>
        </form>
        {type === 'login' && (
          <Typography
            variant="body2"
            color="primary"
            onClick={handlePasswordReset}
            className="modal-forgot-password"
          >
            ¿Olvidaste tu contraseña?
          </Typography>
        )}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleSignIn}
          fullWidth
          className="modal-google-button"
        >
          {type === 'login' ? 'Iniciar Sesión con Google' : 'Registrarse con Google'}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalLogin;
